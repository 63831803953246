/* HomePage.css */

.container {
    position: relative;
    background-color: #ffffff;
    height: 120vh;
}

.header {
    background-color: #282c34;
    padding: 20px;
    color: #E5E4E2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
}

.logo {
    font-size: 1em;
    color: #E5E4E2;
    text-decoration: none; 
}

.nav a,
.nav a:visited { 
    color: #E5E4E2;
    text-decoration: none;
    margin: 0 15px;
}

.nav a:hover {
    color: #cccccc; 
}

.nav a:active {
    color: #aaaaaa; 
}

.main {
    display: flex;
    align-items: center; /* This will vertically align the flex items if they are not the same height */
    justify-content: space-between; /* This will align the toggle button to the left and the upload button section to the center */
    padding: 20px;
}

.text-box {
    background-color: #E5E4E2;
    border-left: 5px solid #0086E7;
    margin: 20px 10px;
    text-align: left;
    margin-bottom: 40px;
}

.text-box p {
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

}

.text-box-visible {
    opacity: 1;
    height: auto;
}

.text-box-hide {
    opacity: 0;
    transform: translateY(-20px);
    display: none;
}
.toggle-button {
    background-color: #0086E7; 
    border: none;
    color: #E5E4E2;
    width: 80px;
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    margin: 10px;
    transition: background-color 0.3s ease;
    margin-top: 20px; 
    margin-bottom: 5px; 
    border-radius: 10px;
    text-align: center;
    margin-right: auto; /* This will push everything else to the opposite side */
}

.toggle-container{
    text-align: left;
}

.toggle-button:hover {
    background-color: #034b7f; 
}

.upload-button {
    background-color: #0086E7;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 auto; /* This will center the upload button section in the available space */
    cursor: pointer;
    border-radius: 5px;
}

.submit-button {
    background-color: #0086E7;
    border: none;
    color: #E5E4E2;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #034b7f;
}

.dropdown {
    border: none; /* Removes the border */
    outline: none; /* Removes the focus outline */
}

.button-container {
    display: flex;
    justify-content: space-between; /* This will push the h1 and button to the extreme sides */
    position: relative; /* Establish a positioning context for absolute positioning */
    align-items: center; /* Align items vertically */
  }

  .header-container{
    display: flex;
    justify-content: center; /* This will push the h1 and button to the extreme sides */
    position: relative; /* Establish a positioning context for absolute positioning */
    align-items: center; /* Align items vertically */
  }
  
  .button-container .randomdownload {
    margin-left: auto; /* Push the button to the right */
  }

  .randomdownload{
    background-color: #808080; 
    border: none;
    color: #E5E4E2;
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    margin: 10px;
    transition: background-color 0.3s ease;
    margin-top: 20px; 
    margin-bottom: 20px; 
    border-radius: 10px;
}

 .randomdownload:hover {
    background-color: #373636; 
}
