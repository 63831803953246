.display-container {
    background-color: #f0f2f5;
    height: 100vh;
    padding-bottom: 20vh;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .flip-card {
    position: relative;
    width: 100%; /* or a specific width, but ensure consistency */
    height: 100%; /* or a specific height, as needed */
    cursor: pointer;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card-front, .flip-card-back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  
  .flip-card-inner.is-flipped {
    transform: rotateY(180deg);
  }

  .second-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
    background-color: #f0f2f5;
  }
  
  .content-box {
    padding-bottom: 2%;
    padding-top: 2%;
    margin-top: 5vh;
    width: 80%;
    height: 220px;
    background-color: #ffffff;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);

  }
  
  .flex-row {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    gap: 6%;
    flex-wrap: wrap;
    height: 100%;
  }
  
  .box {
    flex: 1;
    font-size: 1.5rem;
    color: #000;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
  }
  
  .box-image {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 135%;
    margin-top: -8%;
    z-index: 10; /* This will ensure that the box overlaps any content above it */
  }
    
    .architecture-image,
    .training-image
    {
    height: 120%;
    margin-top: -5%;
    z-index: 10; /* This will ensure that the box overlaps any content above it */
    }

    .architecture-image{
      background-color: #D6A692;
    }

    .training-image{
      background-color: #B0E0E6;
    }

    .prediction-box {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: box-shadow 0.3s ease-in-out;
    }
    
    .prediction-box:hover {
      box-shadow: 0 6px 12px rgba(0,0,0,0.15);
    }
    
    .prediction-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 24px; /* Adjust based on your preference */
      color: #333; /* A darker shade for better readability */
    }
    
    .prediction-title {
      font-weight: bold;
    }

    .prediction-text{
      font-weight: bold;
    }

    .prediction-table{
      width: 100%;
      height: 100%;
      border-collapse: collapse;
    }

    .prediction-table-grid{
      flex-basis: 35%;
      flex-shrink: 0;
      flex-grow: 0;
      border-collapse: collapse;
    }

    .wide-fix {
      flex: 1;
      }
    
    .wide {
    flex-basis: 65%;
    flex-shrink: 0;
    flex-grow: 0;
    }
    
    .narrow {
    flex: 1;
    }

    .image{
      height: 85%;
      width: 85%;
    }
    
    /* Responsive adjustments */
    @media (max-width: 768px) {
    .content-box,
    .flex-row,
    .box
    {
    padding: 1.25rem;
    margin-top: 2.5rem;
    width: 100%;
    }
    
    .flex-row {
    flex-direction: column;
    }
    
    .wide,
    .narrow {
    flex-basis: 100%;
    }
    }
  